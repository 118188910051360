@font-face {
  font-family: 'PrudentialModern';
  font-stretch: 100%;
  src: url('https://branding.123seguro.com/fonts/Prudential%20Modern/PrudentialModern-Bold.ttf');
}
@font-face {
  font-family: 'PrudentialModern';
  font-stretch: condensed;
  src: url('https://branding.123seguro.com/fonts/Prudential%20Modern/PrudentialModern-BoldSemCon.ttf');
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://branding.123seguro.com/fonts/Gilroy/Gilroy%20Light/Gilroy-Light.otf');
  font-weight: 300;
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://branding.123seguro.com/fonts/Gilroy/Gilroy%20Medium/Gilroy-Medium.otf');
  font-weight: 500;
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://branding.123seguro.com/fonts/Gilroy/Gilroy%20Semibold/Gilroy-Semibold.otf');
  font-weight: 600;
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://branding.123seguro.com/fonts/Gilroy/Gilroy%20Bold/Gilroy-Bold.otf');
  font-weight: 700;
}
